import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiEndpoint } from '../config';
import { useNavigate } from 'react-router-dom';
import { trySilentLogin } from '../auth/AuthUtils';
import './CreateMember.css';

const CreateMember = () => {
    const [formData, setFormData] = useState({
        mailingList: false,
        lastName: '',
        volunteer: false,
        firstName: '',
        leadershipInterest: false,
        zipCode: '',
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    useEffect(() => {
        (async () => {
            let idToken = localStorage.getItem('id_token');
            if (!idToken) {
                await trySilentLogin();
                idToken = localStorage.getItem('id_token');
            }
        })();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let idToken = localStorage.getItem('id_token');
        let retryCount = 0;
        const maxRetry = 3;

        while (retryCount < maxRetry) {
            try {
                const response = await axios.post(
                    `${apiEndpoint}/postCreateUser`,
                    { ...formData },
                    {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    setLoading(false);
                    navigate('/');
                    return;
                }
            } catch (error) {
                console.error('Error creating user:', error);
                await trySilentLogin();
                idToken = localStorage.getItem('id_token');
                retryCount++;
            }
        }

        setLoading(false);
        console.error('Failed to create user after maximum retry attempts');
    };

    return (
        <div className="create-member-container">
            <form onSubmit={handleSubmit}>
                <label>
                    First Name:
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    Last Name:
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    Zip Code:
                    <input
                        type="text"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    Volunteer:
                    <input
                        type="checkbox"
                        name="volunteer"
                        checked={formData.volunteer}
                        onChange={handleChange}
                    />
                </label>

                <label>
                    Mailing List:
                    <input
                        type="checkbox"
                        name="mailingList"
                        checked={formData.mailingList}
                        onChange={handleChange}
                    />
                </label>

                <label>
                    Interested in Leadership:
                    <input
                        type="checkbox"
                        name="leadershipInterest"
                        checked={formData.leadershipInterest}
                        onChange={handleChange}
                    />
                </label>

                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? (
                        <>
              <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
              ></span>
                            {' '}
                            Processing...
                        </>
                    ) : (
                        'Submit'
                    )}
                </button>
            </form>

            {loading && (
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )}
        </div>
    );
};

export default CreateMember;
