import React from "react";
import "./Chants.css";

function App() {
    return (
        <div className="full-screen-background">
            <div className="container">
                <div className="song">
                    <h1 className="song-title">Mission Statement</h1>
                    <p className="lyrics">
                        We are more than just supporters.<br />
                        We are the architects of unity: Riptides connect the diversity of San
                        Diego through our passion of the beautiful game<br />
                        We are stewards of positive change: Riptides build tangible impact
                        through our dedication and service<br />
                        We are the Creators of Connection: Riptides unite to elevate each
                        community and one another<br />
                        We are Riptides.
                        <br />
                        <br />
                        <h3 className="song-title">Pillars Of Importance</h3>
                        Community Unity and Diversity:<br />
                        Our first and foremost pillar is community unity through diversity. We stand as a bridge, connecting people of varied backgrounds and experiences through the universal language of soccer. We are committed to nurturing a welcoming environment where newcomers and seasoned fans alike can find a sense of belonging, regardless of their origin or soccer knowledge. Through shared experiences, we cultivate an inclusive community where everyone can contribute and learn.
                        <br />
                        <br />
                        Local Heritage and Identity:<br />
                        Rooted in the rich tapestry of San Diego's heritage, we celebrate and integrate our city's identity into our passionate support. Just as San Diego is a cultural crossroads, we blend our love for soccer with the essence of our local traditions. By embracing the uniqueness of our city and infusing it into our support, we create an authentic experience that resonates with San Diegans.
                        <br />
                        <br />
                        Community Impact and Engagement:<br />
                        Our commitment extends far beyond the confines of the pitch. We recognize the power of collective action and use our platform to drive positive change within our community. Through collaborations with local organizations, grassroots initiatives, and community-driven projects, we aim to leave a lasting impact on San Diego. By engaging with the city's needs, we become a force for meaningful transformation.
                        <br />
                        <br />
                        Empowerment through Shared Knowledge:<br />
                        Education is the cornerstone of empowerment. We are dedicated to promoting a deeper understanding of the sport, its nuances, and its global impact. By fostering a culture of shared knowledge, we enhance the joy of soccer, helping our community appreciate every goal, tactic, and moment with greater depth. Through learning and teaching, we elevate our connection to the game.
                        <br />
                        <br />
                        Passion in Action:<br />
                        Our passion ignites the energy that fills the stadium and resonates through the city. We believe in the power of fervent support that transcends the boundaries of the pitch. Through lively chants, spirited cheers, and active participation, we create an atmosphere that envelops both players and fans in the magic of the moment. Our collective enthusiasm showcases the unifying strength of our city's spirit.
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default App;