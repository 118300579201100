// DiscordJoinPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { discordRedirectUri, discordClientId } from '../config';

const OAUTH_URL = `https://discord.com/api/oauth2/authorize?client_id=${discordClientId}&redirect_uri=${encodeURIComponent(discordRedirectUri)}&response_type=code&scope=identify%20guilds.join`;

const DiscordJoinPage = ({ nickname }) => {
    const history = useNavigate();

    useEffect(() => {
        // Redirect to Discord OAuth URL
        window.location.href = `${OAUTH_URL}&state=${nickname}`;
    }, [nickname]);

    return (
        <div>
            <p>Redirecting you to Discord...</p>
        </div>
    );
};

export default DiscordJoinPage;