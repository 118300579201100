import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { apiEndpoint } from '../config';
import { trySilentLogin } from "../auth/AuthUtils";
import membershipImage from '../assets/2024membership.jpg'; // Ensure the path to your image is correct
import { useNavigate } from 'react-router-dom';


const stripePromise = loadStripe('pk_live_51O9AsPAt1WFL9jmlShQtIPd2lR2qeeht8rz5BSeGg7G3TQx4VTsBTK5VcRWpErXpdE6WUqv4IoEy62cdM8n80PhH00I9u7JcHg');

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [idToken, setIdToken] = useState(null);
    const [donation, setDonation] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [agree, setAgree] = useState(false);
    const membershipPrice = 500; // Membership price in cents ($20)
    const navigate = useNavigate();
    const [memberStatus, setMemberStatus] = useState(localStorage.getItem("MemberStatus"));
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [overlayMessage, setOverlayMessage] = useState('');

    // Retrieve userDetails from local storage or context/state management if needed
    const [userDetails, setUserDetails] = useState(() => {
        const saved = localStorage.getItem("userDetails");

        return saved ? JSON.parse(saved) : null;
    });



    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                fontSize: '18px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
                lineHeight: '24px', // Adjust line height for more vertical space
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };

    const Overlay = ({ isVisible, message }) => {
        console.log("Overlay visibility changed:", overlayVisible);
        if (!isVisible) return null;

        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 18, 40, 0.9)',
                color: 'white',
                fontSize: '24px',
                zIndex: 1000,
            }}>
                {message}
            </div>
        );
    };


    useEffect(() => {
        const fetchToken = async () => {
            let token = localStorage.getItem('id_token');
            if (!token) {
                await trySilentLogin();
                token = localStorage.getItem('id_token');
            }
            setIdToken(token);
        };

        fetchToken();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements || !userDetails || quantity < 1) {
            console.log('Stripe has not loaded, user details are missing, or quantity is invalid.');
            setOverlayVisible(false); // Ensure overlay is not stuck if exiting early
            return;
        }

        setOverlayVisible(true);
        setOverlayMessage('Submitting Payment...');

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (error) {
            console.log(`Payment method creation error: ${error.message}`);
            setOverlayVisible(false); // Hide overlay in case of error
            return; // Exit if there's an error creating the payment method
        }

        const totalAmount = membershipPrice * quantity + (donation ? parseInt(donation) * 100 : 0); // Calculate total amount

        try {
            const response = await fetch(`${apiEndpoint}/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({
                    paymentMethodId: paymentMethod.id,
                    amount: totalAmount,
                    email: userDetails.email,
                    name: `${userDetails.firstName} ${userDetails.lastName}`,
                    description: '2024 Membership Purchase',
                }),
            });

            const paymentIntentResponse = await response.json();
            if (!response.ok || !paymentIntentResponse.clientSecret) {
                throw new Error('Payment intent creation failed');
            }

            const clientSecret = paymentIntentResponse.clientSecret;

            const confirmResult = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id,
            });

            if (confirmResult.error) {
                console.error(`Payment confirmation error: ${confirmResult.error.message}`);
                setOverlayVisible(false); // Hide overlay in case of error
                return; // Exit if there's an error confirming the payment
            }

            if (confirmResult.paymentIntent.status === 'succeeded') {
                console.log('Payment successful');
                console.log(confirmResult.paymentIntent.id);

                setOverlayMessage('Payment Confirmed, Assigning Memberships...');

                // Loop through each membership purchased to call the confirm-payment-membership API
                for (let i = 0; i < quantity; i++) {
                    const confirmResponse = await fetch(`${apiEndpoint}/confirm-payment-membership`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${idToken}`,
                        },
                        body: JSON.stringify({
                            orderId: confirmResult.paymentIntent.id,
                        }),
                    });

                    if (!confirmResponse.ok) {
                        throw new Error('Membership confirmation failed');
                    }

                    const confirmPaymentMembershipResponse = await confirmResponse.json();
                    console.log(`Membership ${i + 1} confirmed:`, confirmPaymentMembershipResponse);
                }

                setOverlayVisible(false); // Hide overlay after all operations are successful
                navigate('/paymentconfirmation'); // Redirect to Thank You page
            }
        } catch (error) {
            console.error(`Error during payment process: ${error.message}`);
            setOverlayVisible(false); // Ensure overlay is hidden in case of any catch block execution
        }
    };


    const totalAmount = (membershipPrice * quantity) + (donation ? parseInt(donation) * 100 : 0); // Calculate total amount in cents

    return (
        <>
        <Overlay isVisible={overlayVisible} message={overlayMessage} />
        <form onSubmit={handleSubmit}>
            <img src={membershipImage} alt="2024 Membership" style={{ width: '100%', maxWidth: '300px', marginBottom: '20px' }} />
            <h2>2024 Membership</h2>
            <p>This membership is non refundable and is a donation for Riptides.</p>
            <div>
                <label>Adult Membership Quantity:
                    <select value={quantity} onChange={(e) => setQuantity(e.target.value)} style={{ marginLeft: '10px' }}>
                        {[...Array(10).keys()].map(i => (
                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                </label>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <label>Additional Donation:
                    <input
                        type="number"
                        value={donation}
                        onChange={(e) => setDonation(e.target.value)}
                        placeholder="Enter additional amount"
                        style={{ marginLeft: '10px', padding: '5px' }}
                    />
                </label>
            </div>

            <div><h1>Total: ${(totalAmount / 100).toFixed(2)} </h1></div>


            <div style={{ padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '4px' }}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>

            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                        style={{ marginRight: '10px' }}
                    />
                    I agree that this is a non-refundable purchase.
                </label>
            </div>

            <button type="submit" disabled={!stripe || !agree}>
                Pay
            </button>
        </form>
            </>
    );
};


        const App = () => {
                              return (
                              <Elements stripe={stripePromise}>
        <CheckoutForm />
        </Elements>
    );
};

export default App;