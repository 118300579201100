import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiEndpoint } from '../config';
import './MemberEdit.css';
import { useNavigate } from 'react-router-dom';
import { trySilentLogin } from '../auth/AuthUtils';

const MemberEdit = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        zipCode: '',
        volunteer: false,
        leadershipInterest: false,
        mailingList: false,
    });

    const [fetchAttempts, setFetchAttempts] = useState(0); // state to track fetch attempts


    const fetchData = async (idToken) => {
        // Your fetch logic here, for example, fetching user details
        setFetchAttempts(fetchAttempts + 1);
    };
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked,
        });
    };

    useEffect(() => {
        (async () => {
            let idToken = localStorage.getItem('id_token');
            if (!idToken) {
                await trySilentLogin();
                idToken = localStorage.getItem('id_token');
            }
            if (idToken && fetchAttempts < 4) {
                fetchData(idToken);
            }
        })();

        const storedUserDetail = localStorage.getItem('userDetails');
        if (storedUserDetail) {
            const userDetail = JSON.parse(storedUserDetail);
            setFormData({
                firstName: userDetail.firstName || '',
                lastName: userDetail.lastName || '',
                zipCode: userDetail.zipCode || '',
                volunteer: userDetail.volunteer || false,
                leadershipInterest: userDetail.leadershipInterest || false,
                mailingList: userDetail.mailingList || false,
            });
        }
    }, [fetchAttempts]);  // Adding fetchAttempts as dependency

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const idToken = localStorage.getItem('id_token');
        try {
            await axios.put(`${apiEndpoint}/postUpdateUser`, formData, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });
            navigate('/member');
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('Error updating profile. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/member');
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="firstName">First Name</label>
                <input id="firstName" name="firstName" type="text" value={formData.firstName} onChange={handleChange} />
            </div>
            <div>
                <label htmlFor="lastName">Last Name</label>
                <input id="lastName" name="lastName" type="text" value={formData.lastName} onChange={handleChange} />
            </div>
            <div>
                <label htmlFor="zipCode">Zip Code</label>
                <input id="zipCode" name="zipCode" type="text" value={formData.zipCode} onChange={handleChange} />
            </div>
            <div>
                <label>
                    <input type="checkbox" name="volunteer" checked={formData.volunteer} onChange={handleCheckboxChange} />
                    Volunteer
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" name="leadershipInterest" checked={formData.leadershipInterest} onChange={handleCheckboxChange} />
                    Interested in Leadership
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" name="mailingList" checked={formData.mailingList} onChange={handleCheckboxChange} />
                    Join Mailing List
                </label>
            </div>
            <div className="button-container">
                <button type="button" onClick={handleCancel}>Cancel</button>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {' '}Loading...
                        </>
                    ) : (
                        'Update Profile'
                    )}
                </button>
            </div>
        </form>
    );
};

export default MemberEdit;
