import React, { useEffect, useRef, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const QRCodeScanner = () => {
    const scannerRef = useRef(null);
    const [scannedValue, setScannedValue] = useState(null); // State to hold the scanned value
    const [scannerActive, setScannerActive] = useState(true); // State to control the display of the scanner

    useEffect(() => {
        let html5QrCodeScanner;

        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            console.log(`Decoded text: ${decodedText}`, decodedResult);
            setScannedValue(decodedText); // Update the state with the decoded text
            setScannerActive(false); // Deactivate the scanner

            // Clear the scanner to stop camera feed
            html5QrCodeScanner.clear();
        };

        const qrCodeErrorCallback = (error) => {
            console.log(`Error scanning QR code: ${error}`);
        };

        const videoErrorCallback = (error) => {
            console.log(`Error scanning QR code: ${error}`);
        };

        const config = {
            fps: 10,
            qrbox: { width: 250, height: 250 }
        };

        if (scannerActive) {
            html5QrCodeScanner = new Html5QrcodeScanner(
                scannerRef.current.id,
                config
            );

            // Start scanning
            html5QrCodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback, videoErrorCallback);
        }

        // Cleanup
        return () => {
            // Clear the scanner to stop camera feed
            if (html5QrCodeScanner) {
                html5QrCodeScanner.clear();
            }
        };
    }, [scannerActive]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            {scannerActive ? (
                <div id="qr-reader" ref={scannerRef}></div>
            ) : (
                <div style={{ fontSize: '24px' }}>
                    Scanned Value: {scannedValue}
                </div>
            )}
        </div>
    );
};

export default QRCodeScanner;
