let redirectUri;
let discordRedirectUri;


// Check for localhost
if (window.location.hostname === 'localhost') {
    redirectUri = 'http://localhost:3000/member';
    discordRedirectUri = 'http://localhost:4000/api/discord/callback';
}
// Check for www.riptides.club
else if (window.location.hostname === 'www.riptides.club') {
    redirectUri = 'https://www.riptides.club/member';
    discordRedirectUri = 'https://www.riptides.club/api/discord/callback';
}
// Default to members.riptides.club
else {
    redirectUri = 'https://members.riptides.club/member';
    discordRedirectUri = 'https://members.riptides.club/api/discord/callback';
}


export const clientId = '7ar5nf9e871iuuot19thttacam';
export { redirectUri }; // Exported for use elsewhere
export const authUrl = 'https://riptides.auth.us-east-1.amazoncognito.com/authorize';
export const tokenUrl = 'https://riptides.auth.us-east-1.amazoncognito.com/token';
export const apiEndpoint = 'https://api.riptides.club/prod';

export const discordClientId = '1161800945055846500'
export const discordClientSecret = 'UDZeLcnP4O3dszvNv8UDm_FSbGGGRDlL'
export { discordRedirectUri };