import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode'; // Using jwt-decode for decoding ID token
import Home from './pages/Home';
import Member from './pages/Member';
import Chants from './pages/Chants';
import CreateMember from './pages/CreateMember';
import Links from './pages/Links';
import Logo from './assets/Crest.png';
import './App.css';
import AssignMemberships from './pages/AssignMemberships';
import CheckIn from './pages/CheckIn';
import { trySilentLogin } from './auth/AuthUtils'; // Ensure correct imports
import {fetchTokens, authorize} from "./auth/authentication";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DiscordJoinPage from './pages/DiscordJoinPage';
import DiscordCallback from './pages/DiscordCallback';
import MemberEdit from './pages/MemberEdit';
import Mission from './pages/Mission';
import MembershipPurchase from "./pages/MembershipPurchase";
import PaymentConfirmation from "./pages/paymentConfirmation";

function App() {
    const [userEmail, setUserEmail] = useState(null);



    function logout() {
        // Clear local storage
        localStorage.clear();

        // Reset application state
        setUserEmail(null);

        // Redirect the user to the home page
        window.location.href = '/';
    }

    return (
        <BrowserRouter>
            <div className="App">
                <header className="App-header">
                    <div className="header-top">
                        <img src={Logo} alt="Company Logo" className="App-logo" />
                        {userEmail && (
                            <button className="logout-button" onClick={logout}>
                                Logout
                            </button>
                        )}
                    </div>
                    <nav>
                        <Link className="nav-link" to="/">Home</Link>
                        <Link className="nav-link" to="/chants">Chants</Link>
                        <Link className="nav-link" to="/mission">Mission</Link>
                        <a className="nav-link" href="https://merch.riptides.club" target="_blank" rel="noopener noreferrer">Shop</a>
                        <Link className="nav-link" to="/member">Member</Link>

                    </nav>
                </header>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/member" element={<Member />} />
                    <Route path="/createMember" element={<CreateMember />} />
                    <Route path="/assignMemberships" element={<AssignMemberships />} />
                    <Route path="/checkin" element={<CheckIn />} />
                    <Route path="/discordjoin" element={<DiscordJoinPage />} />
                    <Route path="/join-discord/:guid" element={<DiscordJoinPage />} />
                    <Route path="/api/discord/callback" element={<DiscordCallback />} />
                    <Route path="/memberEdit" element={<MemberEdit />} />
                    <Route path="/chants" element={<Chants />} />
                    <Route path="/mission" element={<Mission />} />
                    <Route path="/links" element={<Links />} />
                    <Route path="/checkout" element={<MembershipPurchase />} />
                    <Route path="/paymentconfirmation" element={<PaymentConfirmation />} />
                </Routes>
                <footer className="App-footer">
                    <a href="https://www.instagram.com/riptides.club" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={icon({ name: 'instagram', style: 'brands' })} />
                    </a>
                    <a href="https://twitter.com/riptides_club" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={icon({ name: 'twitter', style: 'brands' })} />
                    </a>
                </footer>
            </div>
        </BrowserRouter>
    );
}

export default App;
