import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './AssignMemberships.css';
import { apiEndpoint } from '../config';

const AssignMemberships = () => {
    const location = useLocation();
    const initialPendingMemberships = location.state?.pendingMemberships || [];
    const [pendingMemberships, setPendingMemberships] = useState(initialPendingMemberships);
    const [emailEntries, setEmailEntries] = useState({});
    const [disabledButtons, setDisabledButtons] = useState({});
    const idToken = localStorage.getItem('id_token');

    useEffect(() => {
        const newDisabledButtons = {};

        for (const orderNumber of Object.keys(emailEntries)) {
            const { email, confirmEmail } = emailEntries[orderNumber];
            newDisabledButtons[orderNumber] = email !== confirmEmail;
        }

        setDisabledButtons(newDisabledButtons);
    }, [emailEntries]);

    const handleInputChange = (orderNumber, field, value) => {
        setEmailEntries({
            ...emailEntries,
            [orderNumber]: {
                ...emailEntries[orderNumber],
                [field]: value,
            },
        });
    };

    const handleAssign = async (orderNumber) => {
        try {
            const idToken = localStorage.getItem('id_token');  // Retrieve idToken from local storage

            const matchingMembership = pendingMemberships.find(item => item.orderNumber === orderNumber);
            if (!matchingMembership) {
                console.error("Matching membership not found");
                return;
            }

            const email = emailEntries[orderNumber].email;
            const guid = matchingMembership.guid;

            const response = await axios.post(
                `${apiEndpoint}/postAssignMemberships`,
                {
                    email,
                    guid,
                },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedPendingMemberships = pendingMemberships.filter(item => item.guid !== guid);
                setPendingMemberships(updatedPendingMemberships);

                const updatedEmailEntries = { ...emailEntries };
                delete updatedEmailEntries[orderNumber];
                setEmailEntries(updatedEmailEntries);
            } else {
                console.error("Failed to assign membership");
            }
        } catch (error) {
            console.error("Error in handleAssign: ", error);
        }
    };

    return (
        <div>
            <h1>Assign Memberships Page</h1>
            {pendingMemberships.map((membership, index) => (
                <div key={index} className="membership-entry">
                    <span>Order Number: {membership.orderNumber}</span>
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) =>
                            handleInputChange(membership.orderNumber, 'email', e.target.value)
                        }
                    />
                    <input
                        type="email"
                        placeholder="Confirm Email"
                        onChange={(e) =>
                            handleInputChange(membership.orderNumber, 'confirmEmail', e.target.value)
                        }
                    />
                    <button
                        disabled={disabledButtons[membership.orderNumber]}
                        onClick={() => handleAssign(membership.orderNumber, idToken)} // pass idToken here
                    >
                        Assign
                    </button>
                </div>
            ))}
        </div>
    );
};

export default AssignMemberships;
