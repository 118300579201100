import { fetchTokens, silentLogin, authorize } from './authentication';
import queryString from 'query-string';
import atob from 'atob';

export function decodeIdToken(idToken) {
    const payload = idToken.split('.')[1];
    const decoded = JSON.parse(atob(payload));
    return decoded.email;
}

export async function trySilentLogin() {
    const { code } = queryString.parse(window.location.search);

    if (code) {
        // Attempt to fetch tokens using the authorization code.
        try {
            const data = await fetchTokens(code);
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('id_token', data.id_token);

            // Optionally decode and use the ID token.
            const email = decodeIdToken(data.id_token);
            localStorage.setItem('email', email);
        } catch (error) {
            console.error('Error fetching tokens:', error);
            authorize(); // Redirect for manual login upon token fetch failure.
            return;
        }
    } else {
        // Attempt silent login if no authorization code is present.
        const isSuccess = await silentLogin();
        if (!isSuccess) {
            console.error('Silent login failed. Redirecting for manual login...');
            authorize(); // Redirect for manual login upon silent login failure.
            return;
        }

        // Optionally decode and use the ID token post silent login.
        const idToken = localStorage.getItem('id_token');
        if (idToken) {
            const email = decodeIdToken(idToken);
            localStorage.setItem('email', email);
        }
    }

    // Here, you can add any additional logic needed after successful login.
    // This could involve setting user context, redirecting the user to a dashboard, etc.
}
