import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './paymentConfirmation.css'; // Make sure this import is correct

const ThankYouPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/member');
        }, 3000); // Wait for 3 seconds before redirecting

        return () => clearTimeout(timer); // Cleanup the timer
    }, [navigate]);

    return (
        <div className="centered"> {/* Updated class name for styling */}
            <div> {/* Additional div for text content, keeping it at the top */}
                <h1>Thank You for Your Purchase!</h1>
                <p>Your 2024 Membership has been successfully processed.</p>
                <p>Redirecting...</p>
            </div>
            <div className="spinner"></div> {/* Spinning wheel */}
        </div>
    );
};

export default ThankYouPage;

