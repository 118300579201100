import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorize } from '../auth/authentication';
import membershipImage from '../assets/IMG_3755.jpg'; // Adjust the path as necessary

const Home = () => {

    return (
        <div className="home-background">
            <div className="home-container">
                <div className="home-content">
                    <h1 className="home-header">Welcome to Riptides Supporters Group</h1>
                    <img src={membershipImage} alt="Riptides" style={{ maxWidth: '100%', height: 'auto' }} className="home-image" />
                    <div className="home-instructions">
                        <p>
                            As Riptides, we transcend traditional fandom to forge unity and connections across San Diego's diverse communities through our shared passion for soccer. Our commitment goes beyond the game; we actively engage in creating positive change and strengthening bonds within each neighborhood. Join us to experience the joy and camaraderie of being part of a movement that celebrates both the beautiful game and the power of community!
                        </p>
                    </div>
                    <button className="home-button" onClick={authorize}>
                        Login / Sign Up
                    </button>
                </div>
            </div>
            <div className="bottom">
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    );
};

export default Home;
