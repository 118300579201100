import React from "react";
import "./Chants.css";

function App() {
    return (
        <div className="full-screen-background">
            <div className="container">
                <div className="song">
                    <h1 className="song-title">SDFC Party!</h1>
                    <p className="lyrics">
                        Capo: <br />
                        Everybody’s gonna hang out here tonight<br />
                        <br />
                        Everyone:<br />
                        Tonight!<br />
                        <br />
                        Capo: <br />
                        Everybody’s gunna hang out here alright<br />
                        <br />
                        Everyone:<br />
                        Alright!<br />
                        <br />
                        Everyone:<br />
                        We’ve got nothing better to do than watch the game and have a
                        couple of brews<br />
                        Don’t talk about anyone else, we don’t want to know<br />
                        We are all about San Diego- come on…LET’S GO!<br />
                        <br />
                        <br />
                        Inspiration Black Flag - "TV Party"
                    </p>
                </div>
                <div className="divider"></div>
                <div className="song">
                    <h1 className="song-title">Hoist The Colors</h1>
                    <p className="lyrics">
                        Yo-ho Woven as One<br />
                        Hoist the Colours High<br />
                        Heave Ho San Diego<br />
                        Never Shall We Die<br />
                        <br />
                        <br />
                        Inspiration: Josh Taylor - "Hoist the Colours"<br />
                    </p>
                </div>
                <div className="divider"></div>
                <div className="song">
                    <h1 className="song-title">Chrome Devil</h1>
                    <p className="lyrics">
                        La la la la la la la la la la<br />
                        La la la la<br />
                        La la la la<br />
                        We come together in Azul and Chrome<br />
                        To drink some beer…. And sing for you<br />
                        Whether it’s with many or few…. The chrome devil will come for
                        you!<br />
                        <br />
                        <br />
                        Inspiration: Popular chant - "Nuovo Coro"<br />
                    </p>
                </div>
                <div className="divider"></div>
                <div className="song">
                    <h1 className="song-title">Chucky on the Wing</h1>
                    <p className="lyrics">
                        When you score you make our city sing<br />
                        Chucky on the Wing<br />
                        Chucky Chucky on the Wing<br />
                        Everytimeeeeee You’re on the ball we know.. it’s gunna be a goal<br />
                        Our superstar from Mexico<br />
                        <br />
                        DRUM SOLO - (for duration of 2 repeats of above lyrics)<br />
                        <br />
                        When you score you make our city sing<br />
                        Chucky on the Wing<br />
                        Chucky Chucky on the Wing<br />
                        Everytimeeeeee You’re on the ball we know.. it’s gunna be a goal<br />
                        Our superstar from Mexico<br />
                        <br />
                        DRUM SOLO - (for duration of 2 repeats of above lyrics)<br />
                        <br />
                        La da da da La dadadadadada - REPEAT (x2)<br />
                        <br />
                        <br />
                        Inspiration: Ellie Dixon - "Jota On The Wing" <br />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default App;